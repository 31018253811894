import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";
import asyncComponent from "./helpers/AsyncFunc";

const RestrictedRoute = ({ component: Component, fromPath, isLoggedIn, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isLoggedIn ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        search: `?fromPath=${props.location.pathname.replace(/\//g, "")}`,
                        state: { from: props.location },
                    }}
                />
            )
        }
    />
);

const PublicRoutes = ({ history, isLoggedIn, fromPath }) => {
    return (
        <ConnectedRouter history={history}>
            <div>
                <RestrictedRoute exact path={"/"} isLoggedIn={isLoggedIn} fromPath={fromPath} component={asyncComponent(() => import("./containers/CustomerHome"))} />
                <RestrictedRoute exact path={"/depositMenu"} isLoggedIn={isLoggedIn} fromPath={fromPath} component={asyncComponent(() => import("./containers/DepositMenu"))} />
                <RestrictedRoute exact path={"/depositMenu/deposit"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu/auto"))} />

                <RestrictedRoute exact path={"/depositMenu/bitpayz"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu/bitpayz.transfer"))} />
                <RestrictedRoute exact path={"/depositMenu/bitpayz/qr/create"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu/bitpayz.qr.create"))} />
                <RestrictedRoute exact path={"/depositMenu/bitpayz/qr/show"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu/bitpayz.qr.show"))} />
                <RestrictedRoute exact path={"/depositMenu/bitpayz/qr/status"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu/bitpayz.qr.status"))} />

                <RestrictedRoute exact path={"/depositMenu/impay"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu/impay"))} />
                <RestrictedRoute exact path={"/depositMenu/impayqr"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu/impayqr"))} />
                <RestrictedRoute exact path={"/depositMenu/impayStatus"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu/impayStatus"))} />
                
                <RestrictedRoute exact path={"/withdraw"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/Withdraw"))} />
                <RestrictedRoute exact path={"/paymentHistory"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/PaymentHistory"))} />
                <RestrictedRoute exact path={"/profile"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/CustomerProfile"))} />

                {/* <RestrictedRoute exact path={"/depositMenu/crypto"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu/crypto"))} /> */}
                {/* <RestrictedRoute exact path={"/depositMenu/qr"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu/qr"))} /> */}
                {/* <RestrictedRoute exact path={"/depositMenu/slip"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu/slip"))} /> */}
                {/* <RestrictedRoute exact path={"/cryptoAddress"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/CustomerCryptoAddress"))} /> */}
                {/* <RestrictedRoute exact path={"/upload"} isLoggedIn={isLoggedIn} component={asyncComponent(() => import("./containers/DepositMenu/upload"))} /> */}
                <Route exact path={"/login"} fromPath={fromPath} component={asyncComponent(() => import("./containers/CustomerAuthen"))} />
            </div>
        </ConnectedRouter>
    );
};

export default connect((state) => ({
    isLoggedIn: state.Auth.idToken !== null,
}))(PublicRoutes);
