import config from "../config";
import axios from "axios";
import { clearToken } from "./utility";
import { Map } from "immutable";
import { v4 as uuidv4 } from "uuid";
import CryptoJS from "crypto-js";

const API_URL = config.apiUrl;

export const customerLogout = async () => {
    const currentUser = await getCurrentUser();
    console.log("customerLogout currentUser", currentUser);
    if (!currentUser) {
        return false;
    }
    const { objectId: userId, sessionToken } = currentUser;
    const userRes = await (await axios.post(`${API_URL}/user/logout`, { userId, sessionToken })).data;
    if (userRes.status === "success") {
        clearToken();
        return true;
    } else {
        return false;
    }
};

export const preAuth = async (userInfo) => {
    const { username } = userInfo;
    const authId = uuidv4();
    const encryptData = CryptoJS.AES.encrypt(JSON.stringify({ username, authId }), process.env.REACT_APP_AUTH_KEY).toString();
    const timestamp = Date.now();
    const userRes = await (await axios.post(`${API_URL}/user/login/preAuth`, { encryptData, timestamp })).data;
    if (userRes.status === "success") {
        return { data: userRes.data, timestamp, authId };
    } else {
        throw { message: userRes.error };
    }
};

export const login = async (userInfo) => {
    const { message, timestamp, data, authId } = await preAuth(userInfo);
    if (message) {
        throw {
            message,
        };
    }
    const { auth, preAuthId } = data;
    const bytes = CryptoJS.AES.decrypt(auth, process.env.REACT_APP_AUTH_KEY);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    const { salt, pathId, authId: decryptAuthId } = decryptedData;
    if (authId !== decryptAuthId) {
        throw {
            message: "ข้อมูลไม่ถูกต้อง",
        };
    }

    if (!userInfo.username || !userInfo.password) {
        throw {
            message: "ใส่ข้อมูลให้ครบ",
        };
    }
    const { ipAddress } = userInfo;
    const key = process.env[`REACT_APP_AUTH_PATH_ID_${pathId}`] + `-${salt}`;
    const encryptData = CryptoJS.AES.encrypt(JSON.stringify({ username: userInfo.username.toLowerCase(), password: userInfo.password, timestamp, ipAddress }), key).toString();

    const userRes = await (await axios.post(`${API_URL}/user/login/v3`, { encryptData, preAuthId })).data;
    if (userRes.status === "success") {
        return userRes.user;
    } else {
        throw { message: userRes.error };
    }
};

export const getConfig = async () => {
    const config = await (await axios.get(`${API_URL}/config`)).data;
    return new Map({ ...config.data });
};

export const getCurrentUser = async () => {
    const data = localStorage.getItem("currentUser");
    if (data) {
        const user = JSON.parse(data);
        const userBalance = await (await axios.get(`${API_URL}/user/balance?userId=${user.id}`)).data;
        const { data: balance, creditPoint, currentTurnover } = userBalance;
        return { ...user, balance, creditPoint, currentTurnover };
    } else {
        return null;
    }
};

export const getPaymentTransaction = async (objectId) => {
    const txData = await (await axios.get(`${API_URL}/deposit/paymentTransaction?objectId=${objectId}`)).data;
    if (txData.status === "success") {
        return txData.data;
    } else {
        return null;
    }
};

export const getUserTransaction = async (userId) => {
    const wList = await (await axios.get(`${API_URL}/withdraw/list?userId=${userId}`)).data;
    const { data: w } = wList;
    const dList = await (await axios.get(`${API_URL}/deposit/list?userId=${userId}`)).data;
    const { data: d } = dList;

    const t = d
        .map((item) => {
            item.className = "Deposit";
            return item;
        })
        .concat(
            w.map((item) => {
                item.className = "Withdraw";
                return item;
            })
        );

    const mapped = t.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
    return mapped.length >= 10 ? mapped.slice(0, 10) : mapped;
};
