const env = {};
const PROD = true;
const PORT = 1337;
const URL = PROD ? "https://lb01.n8.autobet-th.com" : "http://localhost";
env.url = URL;
env.apiUrl = `${URL}:${PORT}/api/v1`;
env.parseServerUrl = `${URL}:${PORT}/parse`;
env.loadBalance = `https://lb01.n8.autobet-th.com:1339/api/v1`;
env.lineURL = "https://lin.ee/EGWBm6T";
env.appName = "Natural8 Thailand";
env.version = "2.0.0";

env.provider = "N8";
env.primaryColor = "#CE1F6A";
env.secondnaryColor = "#CE1F6A";
env.headerColor = "#1d072e";

export default env;
